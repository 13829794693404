import React from 'react';

import { PrimaryButton, Flex, Text } from 'flicket-ui';

import { Layout } from '~components';

import { withAuth } from '~lib/helpers';

const NotFound = () => (
  <Layout title="Page not found">
    <Flex
      w="100%"
      alignItems="center"
      flexDir="column"
      justifyContent="center"
      flex="1"
      px={{ _: 2, md: 4 }}
      py={4}
      bg="N100"
    >
      <Flex
        alignItems="center"
        flexDir="column"
        justifyContent="center"
        w="100%"
        maxW="480px"
        bg="white"
        px={{ _: 2, md: 4 }}
        py={4}
        borderRadius="lg"
        boxShadow="container"
      >
        <Text textAlign="center" fontSize={6} mb={{ _: 0, md: 1 }}>
          404
        </Text>
        <Text textAlign="center">
          We could not find the page you were looking for.
        </Text>
        <PrimaryButton to="/" mt={{ _: 2, lg: 3 }}>
          Back to home
        </PrimaryButton>
      </Flex>
    </Flex>
  </Layout>
);

export default withAuth(NotFound);
